<template>
	<v-alert prominent text type="error" color="red">
		{{ audioIfIsNotValidated() }}
		<v-row class="text-center" :class="{ 'align-column': $vuetify.breakpoint.smAndDown }">
			<v-col class="grow">
				<strong
					>OUPS !<br />
					Tu n'as pas assez de bonnes réponses pour débloquer l'exercice suivant</strong
				>
				<p class="fs15">
					Il te faut au minimum 80% de bonnes réponses aux quiz (ton score =
					{{ totalScoreInPercent }}%)
				</p>
			</v-col>
		</v-row>
	</v-alert>
</template>

<script>
import exerciseNotValidated from '@/assets/sound/exercise-not-validated.mp3';

export default {
	name: 'ScoreFinal',
	props: {
		totalScoreInPercent: Number,
	},
	data() {
		return {
			storageKeySound: 'acceptSound',
		};
	},
	methods: {
		audioIfIsNotValidated() {
			const cookie = window.localStorage.getItem(this.storageKeySound);
			var exerciseNotValidatedAudio = new Audio(exerciseNotValidated);
			if (cookie == null) {
				exerciseNotValidatedAudio.play();
			}
		},
	},
};
</script>

<style></style>
