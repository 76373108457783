<template>
	<div id="GoToReportBlock">
		{{ audioValidated() }}
		<v-card
			v-if="display !== 'end-normal-exercise' && display !== ''"
			class="div-report white--text d-flex align-center flex-column flex-md-row"
			:class="backgroundColor"
		>
			<div class="left-block">
				<v-img
					v-if="display === 'end-of-category'"
					src="@/assets/images/goToReport/end-category.png"
					class="image"
				></v-img>
				<v-img
					v-else-if="display === 'end-of-parcours'"
					src="@/assets/images/goToReport/end-parcours.png"
					class="image"
				></v-img>
				<v-img
					v-else-if="display === 'end-of-formation'"
					src="@/assets/images/goToReport/end-formation.png"
					class="image"
				></v-img>
				<h2 class="ml-2 mr-3">{{ content }}</h2>
			</div>
			<v-btn
				:to="reportUrl"
				medium
				class="button-report mr-0 mr-md-4 mt-2 mt-md-0 black--text"
				color="black"
				dark
				@click="$confetti.stop()"
			>
				Voir mon rapport</v-btn
			>
		</v-card>
		<v-alert v-else-if="display === 'end-normal-exercise'" prominent type="success" color="#2dc7ae">
			<strong
				>Félicitations, tu débloques la prochaine partie avec {{ totalScoreInPercent }} % de bonnes réponses</strong
			>
		</v-alert>
	</div>
</template>

<script>
import { CategoryStatus } from '@/constants/category';
import exerciseValidated from '@/assets/sound/exercise-validated.mp3';
import categoryFinished from '@/assets/sound/next-program-unlocked.mp3';
import parcoursFinished from '@/assets/sound/next-parcours-unlocked.mp3';
import formationFinished from '@/assets/sound/formation-finished.mp3';

export default {
	name: 'SessionGoToReportBlock',
	props: {
		formationProgress: Object,
		session: Object,
		totalScoreInPercent: Number,
	},
	data() {
		return { display: '', notRedoExercise: false, storageKeySound: 'acceptSound', categoryId: null };
	},
	computed: {
		content() {
			if (this.display === 'end-of-category') return 'Félicitations, tu as terminé ton programme !';
			if (this.display === 'end-of-parcours') return 'Félicitations, tu viens de débloquer ton prochain parcours';
			if (this.display === 'end-of-formation') return 'Félicitations, tu viens de finir ta formation';
			else return '';
		},
		reportUrl() {
			if (this.display === 'end-of-category')
				return `/report/redaction/category/${this.formationProgress.formation._id}/${this.categoryId}?notRedo=${this.notRedoExercise}`;
			if (this.display === 'end-of-parcours') {
				const parcoursId = this.findParcoursIdInFormationConfig(this.session.cat_id);
				return `/report/redaction/parcours/${this.formationProgress.formation._id}/${parcoursId}?notRedo=${this.notRedoExercise}`;
			}
			if (this.display === 'end-of-formation') {
				const parcoursId = this.findParcoursIdInFormationConfig(this.session.cat_id);
				return `/report/redaction/end-formation/${this.formationProgress.formation._id}/${parcoursId}?notRedo=${this.notRedoExercise}`;
			} else return '';
		},
		backgroundColor() {
			if (this.display === 'end-of-category') return 'darkPurple';
			if (this.display === 'end-of-parcours') return 'violet';
			if (this.display === 'end-of-formation') return 'darkPurple';
			else return '';
		},
	},
	mounted() {
		this.notRedoExercise = this.$route.query.notRedo === 'true' || this.$route.query.notRedo === true; // Because when redirect from track page. notRedo is Boolean, but when refresh browser it's a String
		let thisCategory = null;
		let thisParcoursItem = null;
		let isThisLastCatOfParcours = false;
		this.formationProgress.config.parcours_list.forEach((parcoursItem) => {
			const foundCategory = parcoursItem.parcours_id.categories.find(
				(category) => category._id === this.session.cat_id
			);
			if (foundCategory != null) {
				thisCategory = foundCategory;
				thisParcoursItem = parcoursItem;
				isThisLastCatOfParcours = this.isLastCategoryOfParcours(parcoursItem.parcours_id);
			}
		});

		if (thisCategory == null) return;
		this.categoryId = thisCategory._id;

		const isThisLastSessionOfCategory = this.isLastSessionOfCategory(this.session._id, thisCategory.sessions);
		const isLastParcoursOfFormation = this.isLastParcoursOfFormation(
			thisParcoursItem,
			this.formationProgress.config.parcours_list
		);
		if (isThisLastSessionOfCategory && isThisLastCatOfParcours && isLastParcoursOfFormation)
			this.display = 'end-of-formation';
		else if (isThisLastSessionOfCategory && isThisLastCatOfParcours) this.display = 'end-of-parcours';
		else if (isThisLastSessionOfCategory) this.display = 'end-of-category';
		else this.display = 'end-normal-exercise';
	},
	methods: {
		audioValidated() {
			const cookie = window.localStorage.getItem(this.storageKeySound);
			const exerciseValidatedAudio = new Audio(exerciseValidated);
			const categoryFinishedAudio = new Audio(categoryFinished);
			const parcoursFinishedAudio = new Audio(parcoursFinished);
			const formationFinishedAudio = new Audio(formationFinished);

			if (cookie == null) {
				if (this.display == 'end-of-formation') formationFinishedAudio.play();
				else if (this.display == 'end-of-parcours') parcoursFinishedAudio.play();
				else if (this.display == 'end-of-category') categoryFinishedAudio.play();
				else if (this.display == 'end-normal-exercise') exerciseValidatedAudio.play();
			}
		},
		isLastSessionOfCategory(sessionId, sessionsOfCategory) {
			const lastSession = sessionsOfCategory.sort((a, b) => b.position - a.position)[0];
			if (lastSession == null) return;
			return lastSession._id === sessionId;
		},
		isLastCategoryOfParcours(parcours) {
			// Because category can be done without order. Therefore, last category of parcour is if number of has been category + 1 == total category of parcours
			const totalPassedCategory = parcours.categories.filter((category) => {
				return category.status === CategoryStatus.COMPLETED;
			}).length;
			if (totalPassedCategory === parcours.categories.length) return true;

			const haveLockedParcours = parcours.categories.find((category) => category.status === CategoryStatus.LOCKED);
			if (haveLockedParcours) return false;

			const haveOpenParcours = parcours.categories.find((category) => category.status === CategoryStatus.OPEN);
			if (haveOpenParcours) return false;

			return totalPassedCategory + 1 === parcours.categories.length;
		},
		isLastParcoursOfFormation(parcoursItem, parcoursListItems) {
			const lastParcoursItem = [...parcoursListItems].sort((a, b) => b.position - a.position)[0];
			return parcoursItem.position === lastParcoursItem.position;
		},
		findParcoursIdInFormationConfig(categoryId) {
			let parcoursId = null;
			this.formationProgress.config.parcours_list.forEach((parcoursItem) => {
				const foundCategory = parcoursItem.parcours_id.categories.find((category) => category._id === categoryId);
				if (foundCategory != null) parcoursId = parcoursItem.parcours_id._id;
			});

			return parcoursId;
		},
	},
};
</script>

<style lang="scss" scoped>
#GoToReportBlock {
	z-index: 2;
}
.div-report {
	position: sticky;
	top: 60px;
	z-index: 10;
	padding-bottom: 10px;
	height: fit-content;
	min-height: 150px;
	justify-content: space-between;

	.button-report {
		background-color: white !important;
	}
	h2 {
		font-size: 20px;
	}

	.left-block {
		display: flex;
		align-items: center;

		.image {
			margin-left: 9px;
			max-width: 110px;
			max-height: 110px;
		}
	}
}

.darkPurple {
	background-color: #120136;
}
.violet {
	background-color: #7854f7;
}

@media screen and (max-width: 959px) {
	.div-report {
		.left-block {
			flex-direction: column;
			.image {
				margin: 10px 0;
			}
			h2 {
				margin-bottom: 20px;
			}
		}
	}
	.button-report {
		margin-bottom: 8px;
	}
}

@media screen and (max-width: 600px) {
	.div-report {
		.left-block {
			h2 {
				text-align: center;
				margin-bottom: 0px;
				font-size: 19px;
			}
		}
	}
}
</style>
